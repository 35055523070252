document.addEventListener('DOMContentLoaded', function() {
    // Hole das Formularfeld mit der ID "landingpage"
    var landingpageField = document.getElementById('input-f92b6e1c');

    // Prüfe, ob das Feld existiert, bevor du versuchst, einen Wert darauf zu setzen
    if (landingpageField) {
        // Setze den Wert des Feldes auf den Pfad (Slug) der aktuellen URL
        landingpageField.value = window.location.pathname;
    } else {
        console.error('Es wurde kein Element mit der ID "landingpage" gefunden.');
    }
});
